import { Button } from 'components/atoms/Button';
import { EInputSize, TextInput } from 'components/atoms/TextInput';
import { useAxios } from 'hooks/axios-context';
import React, { FC, useCallback, useEffect, useState } from 'react';
import PhoneInput from 'react-phone-input-2';
import { Modal } from 'react-responsive-modal';
import { useHistory } from 'react-router';
import styled from 'styled-components';
import isMobilePhone from 'validator/es/lib/isMobilePhone';
import 'react-phone-input-2/lib/style.css';

import { colors } from 'constants/colors';
import CountDownTimer from 'constants/countDownTimer';

import { useCurrentUser } from '../hooks/user-context';

interface VerifyDeviceProps {
  loading: boolean;
  sendCode: () => Promise<void>;
  setTimeOut: (value: boolean) => void;
  setTwoFaCode: (value: string) => void;
  textResent?: string;
  timeOut: boolean;
  twoFaCode: string | number;
  verifyCode: () => Promise<void>;
}

const VerifyDevice: FC<VerifyDeviceProps> = ({
  loading,
  sendCode,
  setTimeOut,
  setTwoFaCode,
  textResent,
  timeOut,
  twoFaCode,
  verifyCode
}) => {
  return (
    <FormWrapper
      id="verify-device"
      onKeyPress={(e) => {
        if (e.key === 'Enter') {
          e.preventDefault();
          verifyCode();
        }
      }}
    >
      <BottomWrapper>
        <TextInput
          className="input-authentication"
          name="twoFaCode"
          onChange={(e) => setTwoFaCode(e.target.value)}
          placeholder="Enter Verification Code"
          width={EInputSize.Small}
        />
        <Button
          disabled={loading || !twoFaCode}
          displayText={loading ? 'Loading...' : 'Verify'}
          onClick={() => verifyCode()}
          style={{ fontSize: '17px', height: '50px' }}
          type="submit"
        />
      </BottomWrapper>
      {!timeOut ? (
        <CountDownTimer
          seconds={60}
          setTimeOut={setTimeOut}
          text={textResent}
        />
      ) : (
        <p>
          If you don't receive a code within two minutes,{' '}
          <ClickHere onClick={() => sendCode()}>
            click here to resend.
          </ClickHere>{' '}
        </p>
      )}
    </FormWrapper>
  );
};

interface SetupDeviceProps {
  loadingCode: boolean;
  sendCode: (phone: string) => Promise<void>;
  setUserPhone: (v: string) => void;
  userPhone: string;
}

const SetupDevice: FC<SetupDeviceProps> = ({
  loadingCode,
  sendCode,
  userPhone
}) => {
  const [phone, setPhone] = useState<string>('');

  return (
    <InputPhoneWrapper>
      <>
        <PhoneInput
          containerStyle={{ width: 'auto' }}
          country={'us'}
          countryCodeEditable={false}
          enableSearch
          inputProps={{
            autoFocus: true,
            id: 'phone-input',
            name: 'phone',
            required: true
          }}
          inputStyle={{
            fontWeight: 'bold',
            padding: '25px',
            paddingLeft: '50px'
          }}
          onChange={(v) => setPhone(v.startsWith('+') ? v : `+${v}`)}
          placeholder="Enter phone number"
          searchPlaceholder={'search country'}
          value={userPhone}
        />
      </>
      <Button
        disabled={loadingCode}
        displayText={loadingCode ? 'sending...' : 'Send Code'}
        onClick={async () => sendCode(phone)}
        style={{ fontSize: '17px', height: '50px' }}
      />
    </InputPhoneWrapper>
  );
};

interface AuthenticationProps {
  logout: () => Promise<void>;
  onCloseModal: () => void;
  open: boolean;
}

const AuthenticationModal: FC<AuthenticationProps> = ({
  logout,
  onCloseModal,
  open
}) => {
  const { axios } = useAxios();
  const { setUser, user } = useCurrentUser();
  const [twoFaCode, setTwoFaCode] = useState<string | number>('');
  const [loadingCode, setLoadingCode] = useState<boolean>(false);
  const [userPhone, setUserPhone] = useState<string>('');
  const [errorMsg, setErrorMsg] = useState<string | null>(null);
  const [userVerify, setUserVerify] = useState<boolean>(false);
  const [isCodeSent, setIsCodeSent] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [timeOut, setTimeOut] = useState<boolean>(true);
  const [textResent, setTextResent] = useState<string>('');
  const { push } = useHistory();

  useEffect(() => {
    if (!open && !userVerify) {
      logout();
    }
  }, [open, logout, userVerify]);

  const sendCode = useCallback(
    async (phone?: string): Promise<void> => {
      setLoadingCode(true);
      setErrorMsg('');

      if (phone && !isMobilePhone(phone) && !user?.phone) {
        setErrorMsg('Phone number format is incorrect');
        setLoadingCode(false);
        return;
      }

      setErrorMsg(null);
      const { data } = await axios.post('/clients/2fa/generate', {
        phone: user?.phone || phone
      });
      setTimeOut(false);

      if (data.success) {
        setErrorMsg('');
        setTextResent('Code resent');
        setIsCodeSent(data.success);
        setLoadingCode(false);
      }
    },
    [axios, user?.phone]
  );

  const sendCodeFirstTime = useCallback(
    async (phone: string) => {
      setUserPhone(phone);
      await sendCode(phone);
      setTextResent('');
    },
    [sendCode]
  );

  const sendCodeOnRender = useCallback(async () => {
    await sendCode();
    setTextResent('');
  }, [sendCode]);

  useEffect(() => {
    if (user && user?.phone && !twoFaCode) {
      sendCodeOnRender();
    }
  }, [sendCodeOnRender, user, twoFaCode]);

  const verifyCode = async (): Promise<void> => {
    setLoading(true);
    setErrorMsg('');

    if (!twoFaCode) {
      setLoading(false);
      setErrorMsg('Verification Code is empty');
      return;
    }

    const { data } = await axios.post('/clients/2fa/verify', {
      code: twoFaCode,
      phone: userPhone || ''
    });
    if (data.success) {
      setErrorMsg('');
      setLoading(false);
      setUserVerify(data.success);
      if (user) {
        setUser({ ...user, isAuthenticated: true });
      }
      push('/funds');
    } else {
      setLoading(false);
      setErrorMsg('Code is not correct');
    }
  };

  const codedPhoneNumber = (phone: string | undefined): string => {
    if (!phone) return '.';
    const phoneCut = phone.substring(2, phone.length - 2);
    return `to ${phone.replace(phoneCut, '*******')}.`;
  };

  return (
    <Modal
      center
      onClose={onCloseModal}
      open={open}
      styles={{
        modal: {
          padding: 0
        },
        modalContainer: {
          overflow: 'hidden',
          width: '100%'
        }
      }}
    >
      <ModalWrapper>
        <h2>Two-Factor Authentication Required</h2>
        <ModalParagraph>
          {!isCodeSent && !user?.phone ? (
            <b>
              Please enter a phone number below to set up your device for
              two-factor authentication.
            </b>
          ) : (
            <b>
              Please enter the verification code sent{' '}
              {codedPhoneNumber(user?.phone || userPhone)}
            </b>
          )}
        </ModalParagraph>
        {!isCodeSent && !user?.phone ? (
          <SetupDevice
            loadingCode={loadingCode}
            sendCode={sendCodeFirstTime}
            setUserPhone={setUserPhone}
            userPhone={userPhone}
          />
        ) : (
          <VerifyDevice
            loading={loading}
            sendCode={sendCode}
            setTimeOut={setTimeOut}
            setTwoFaCode={setTwoFaCode}
            textResent={textResent}
            timeOut={timeOut}
            twoFaCode={twoFaCode}
            verifyCode={verifyCode}
          />
        )}

        {errorMsg && <ErrorMessage>{errorMsg}</ErrorMessage>}
        <WarningBox>
          <p>
            If you no longer have access to your two-factor device, please
            contact{' '}
            <AdminContact href="mailto:fundportaladmin@gunder.com">
              fundportaladmin@gunder.com
            </AdminContact>{' '}
            to set up a new device.
          </p>
        </WarningBox>
      </ModalWrapper>
    </Modal>
  );
};

export default AuthenticationModal;

const InputPhoneWrapper = styled.div`
  align-items: center;
  display: grid;
  gap: 10px;
  grid-template-columns: 1fr 0.5fr;
  justify-content: center;
  justify-items: center;
  padding-bottom: 20px;
  padding-top: 30px;
`;

const BottomWrapper = styled.div`
  align-items: center;
  display: grid;
  gap: 10px;
  grid-template-columns: 1fr 0.6fr;
  justify-items: center;
  padding-bottom: 20px;
  padding-top: 20px;
  place-content: center;
`;

const ModalWrapper = styled.form`
  display: flex;
  flex-direction: column;
  gap: 5px;
  padding: 30px 25px;
  padding-bottom: 0px;
  place-items: center;
`;

const ModalParagraph = styled.div`
  margin-bottom: 15px;
  text-align: center;
`;
const WarningBox = styled.div`
  margin: 15px 0px;
  padding: 5px;
  width: 100%;
`;

const ErrorMessage = styled.div`
  color: ${colors.errors.primary};
  margin-bottom: 2px;
  margin-top: 2px;
  text-align: center;
`;

const AdminContact = styled.a`
  color: ${colors.dark.green};
`;

const FormWrapper = styled.form`
  text-align: center;
`;

const ClickHere = styled.span`
  color: ${colors.dark.green};
  cursor: pointer;
  font-weight: 400px;
  text-decoration: underline;
`;
