/* eslint-disable sort-keys */
import dateFormat from 'dateformat';
import { CurrentFund } from 'screens/FundDetail';

import { resolveStatus, status } from './../constants/status';
import { IInvestor } from './../types/investor';
import { resolveMultiFund } from './multi-fund-format';

export const formatter = new Intl.NumberFormat('en-US', {
  currency: 'USD',
  minimumFractionDigits: 2,
  style: 'currency'
});

interface GeneralInfo {
  capitalCommitment: number | string;
  capitalCommitmentFund2: number | string;
  capitalCommitmentFund3: number | string;
  types: string[];
}

export interface InvestorFunds {
  generalInfo: GeneralInfo;
  status?: string;
}

export const checkGeneralInfoCompleted = (info: GeneralInfo): boolean => {
  if (!info) return false;
  const fields = [
    'name',
    'namePrimaryContact',
    'emailPrimaryContact',
    'addressPrimaryContact',
    'phonePrimaryContact'
  ];
  let generalInfoCompleted = true;

  fields.forEach((field) => {
    if (
      !info[field as keyof GeneralInfo] ||
      info[field as keyof GeneralInfo] === ''
    ) {
      generalInfoCompleted = false;
    }
  });

  if (!info.types || info.types.length === 0) {
    generalInfoCompleted = false;
  }
  if (
    (!info.capitalCommitment || info.capitalCommitment === '0') &&
    (!info.capitalCommitmentFund2 || info.capitalCommitmentFund2 === '0') &&
    (!info.capitalCommitmentFund3 || info.capitalCommitmentFund3 === '0')
  ) {
    generalInfoCompleted = false;
  }

  return generalInfoCompleted;
};

export const nFormatter = (num: number): any => {
  if (num >= 1000000000) {
    return (num / 1000000000).toFixed(1).replace(/\.0$/, '') + 'B';
  } else if (num >= 1000000) {
    return (num / 1000000).toFixed(1).replace(/\.0$/, '') + 'M';
  } else if (num !== 0) {
    return num
      .toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD'
      })
      .replace(/[$]|([.])\w+/g, '');
  }
  return num;
};

export const calculateAmountClosedToDate = (fund: {
  investorFunds: InvestorFunds[];
  limitedPartnerFunds: InvestorFunds[];
}): number | string => {
  let closedAmt = 0;

  if (fund) {
    fund.investorFunds.forEach((inv) => {
      const capital = inv.generalInfo?.capitalCommitment
        ? inv.generalInfo.capitalCommitment
        : 0;

      if (inv.status === 'Closed') {
        closedAmt += Number(capital);
      }
    });
    fund.limitedPartnerFunds.forEach((inv) => {
      const capital = inv.generalInfo?.capitalCommitment
        ? inv.generalInfo.capitalCommitment
        : 0;

      if (inv.status === 'Closed') {
        closedAmt += Number(capital);
      }
    });
  }

  return formatter.format(closedAmt);
};

export const calculateAllCapital = (
  fund: {
    investorFunds: InvestorFunds[];
    limitedPartnerFunds: InvestorFunds[];
  },
  key = 'capitalCommitment' as keyof GeneralInfo
): number | string => {
  let allCapital = 0;

  if (fund) {
    fund.investorFunds.forEach((inv) => {
      let capital = inv.generalInfo[key] ? inv.generalInfo[key] : 0;
      capital = capital.toString().replace(/[,\$]/g, '');
      allCapital += Number(capital);
    });
    fund.limitedPartnerFunds.forEach((inv) => {
      let capital = inv.generalInfo[key] ? inv.generalInfo[key] : 0;
      capital = capital.toString().replace(/[,\$]/g, '');
      allCapital += Number(capital);
    });
  }

  return formatter.format(allCapital);
};

export const getNotYetReadyToCloseStatus = (
  funds: any[],
  status1: string,
  status2?: string,
  status3?: string,
  status4?: string,
  capitalCommitment = 'capitalCommitment'
): { capital: number; count: number } => {
  let filteredResults: any = [];
  if (funds && funds.length > 0) {
    filteredResults = funds.filter(
      (investor) =>
        investor.status === status1 ||
        investor.status === status2 ||
        investor.status === status3 ||
        investor.status === status4 ||
        !investor.status
    );
  }
  let capital = 0;

  if (filteredResults.length > 0) {
    filteredResults.forEach((investor: any) => {
      if (investor.generalInfo && investor.generalInfo[capitalCommitment]) {
        capital += Number(
          investor.generalInfo[capitalCommitment].replace(/[^0-9.-]+/g, '')
        );
      }
    });

    return { capital, count: filteredResults.length };
  } else {
    return { capital, count: 0 };
  }
};

export const getStatusInfo = (
  funds: any[],
  status: string,
  capitalCommitment = 'capitalCommitment'
): { capital: number; count: number } => {
  let capital = 0;
  if (funds && funds.length > 0) {
    const filteredResults = funds.filter(
      (investor) => investor.status === status
    );

    if (filteredResults.length > 0) {
      filteredResults.forEach((investor) => {
        if (investor.generalInfo && investor.generalInfo[capitalCommitment]) {
          capital += Number(
            investor.generalInfo[capitalCommitment].replace(/[^0-9.-]+/g, '')
          );
        }
      });

      return { capital, count: filteredResults.length };
    }
  }

  return { capital, count: 0 };
};

export const formatDataFunds = (funds: any[]): any => {
  const results: any = [];
  //the Gunder admin choose ‘No’, then none of the data should be visible to clients in the Client Access feature.
  const filteredFunds = funds.filter(
    (fund) => fund.includeFundGpAccess === 'yes'
  );
  // we filter multi funds to resolve them
  const multiFundResolved = resolveMultiFund(filteredFunds);
  if (multiFundResolved && multiFundResolved.length > 0) {
    multiFundResolved.forEach((fund: any) => {
      results.push(fund);
    });
  }
  if (filteredFunds && filteredFunds.length > 0) {
    filteredFunds.forEach((fund: any) => {
      if (fund.fundType.identifier !== 'limited_partnership_multi-fund') {
        if (fund.fundType.identifier !== 'partnership_abbr_multi-fund') {
          results.push({
            fundName: fund.fundLegalName,
            allCapital: calculateAllCapital(fund),
            capitalClosed: calculateAmountClosedToDate(fund),
            notYetReadyToClose: formatter.format(
              getNotYetReadyToCloseStatus(
                [...fund.investorFunds, ...fund.limitedPartnerFunds],
                status.IN_PROGRESS,
                status.PENDING,
                status.SUBMITTED_TYPE,
                status.SUBMITTED
              ).capital
            ),
            readyToClose: formatter.format(
              getStatusInfo(
                [...fund.investorFunds, ...fund.limitedPartnerFunds],
                status.READY_TO_CLOSE
              ).capital
            ),
            id: fund._id
          });
        }
      }
    });
  }
  return results;
};

export const formatDataInvestors = (
  investors: any[],
  currentFund: CurrentFund
): any => {
  const filteredCSV = investors
    .filter((inv: IInvestor) => inv?.status !== 'omitted')
    .filter((inv: IInvestor) => checkGeneralInfoCompleted(inv.generalInfo));
  if (filteredCSV && filteredCSV.length > 0) {
    const results = filteredCSV.map((inv: IInvestor) => {
      return {
        name: inv.generalInfo?.name,
        lpGroup: inv?.lpGroup
          ? inv?.lpGroup.replace(/\s{2,}/g, ' ').trim()
          : '',
        capitalCommitment: `${formatter.format(
          inv.generalInfo ? inv.generalInfo[currentFund.capital] : 0
        )}`,
        notes: inv.notes ? inv.notes.replace(/\s{2,}/g, ' ').trim() : '',
        closedAt: dateFormat(
          new Date(inv.closedAt || inv.updatedAt),
          'dd mmm yyyy'
        ),
        status: statusDate(
          resolveStatus(inv.status, inv.submitted),
          dateFormat(new Date(inv.closedAt || inv.updatedAt), 'dd mmm yyyy')
        ),
        contactName: inv.generalInfo?.namePrimaryContact || '',
        contactEmail: inv.generalInfo?.emailPrimaryContact || ''
      };
    });
    return results;
  }
};

// format: {status} - {date}
const statusDate = (status: string, date: string): string => {
  if (
    date &&
    (status === 'Closed' || status === 'Submitted' || status === 'In Progress')
  ) {
    return `${status} - ${date}`;
  }
  return status;
};

export const formatStatusLabel = (
  _status: string,
  submitted: boolean
): string => {
  if (_status === 'Submitted') {
    return status.SUBMITTED;
  }
  if (_status === status.PENDING && submitted) {
    return status.SUBMITTED;
  } else if (_status === status.PENDING) {
    return status.IN_PROGRESS;
  }
  return _status;
};

export const formatFullAmount = (amount: number): string | number => {
  if (amount) {
    return amount.toLocaleString('en-US', {
      style: 'currency',
      currency: 'USD'
    });
  }
  return `$${amount}`;
};
