/* eslint-disable sort-keys */
import { Tooltip, TooltipExpand } from 'components/atoms/Tooltips';
import dateFormat from 'dateformat';
import React, { useState, useEffect, ReactElement } from 'react';
import { Redirect, useParams } from 'react-router-dom';
import styled from 'styled-components/macro';

import { NoteIcon } from './../components/atoms/icons/NoteIcon';
import { NoteIconOpacity } from './../components/atoms/icons/NoteIconOpacity';
import { InfoBox } from './../components/atoms/InfoBox';
import { DataTable } from './../components/organisms/DataTable';
import { colors } from './../constants/colors';
import { fundDetailHeaders, pagination } from './../constants/pagination';
import { status, resolveStatus } from './../constants/status';
import { fundDetailTableColumns } from './../constants/tableColumns';
import { useAxios } from './../hooks/axios-context';
import { useCurrentUser } from './../hooks/user-context';
import { Layout } from './../navigation/Layout';
import { IFund } from './../types/fund';
import { IInvestor } from './../types/investor';
import {
  checkGeneralInfoCompleted,
  formatDataInvestors,
  formatFullAmount,
  formatStatusLabel,
  formatter,
  nFormatter
} from './../utils/format';

let page = 1;

type ResultsType = {
  capitalCommitment: string;
  contactEmail: string;
  contactName: string;
  lpGroup?: string;
  name: string;
  notes: string;
  status: string;
  statusContent: ReactElement;
};

export interface CurrentFund {
  capital:
    | 'capitalCommitment'
    | 'capitalCommitmentFund2'
    | 'capitalCommitmentFund3';
  legalName: string;
}

export const FundDetail: React.FC = () => {
  const { fundNumber, id } = useParams<{ fundNumber: string; id: string }>();
  const { axios } = useAxios();
  const [results, setResults] = useState<ResultsType[]>([]);
  const [csvData, setCSVData] = useState<ResultsType[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [searchText, setSearchText] = useState<string>('');
  const [fund, setFund] = useState<any>({
    fundLegalName: '',
    fund2LegalName: '',
    fund3LegalName: ''
  });
  const [pages, setPages] = useState<number[]>([1]);
  const [columnSelected, setColumnSelected] = useState('');
  const [sortDir, setSortDir] = useState<'asc' | 'desc' | ''>('');
  const [sortClick, setSortClick] = useState<number>(0);

  const { user } = useCurrentUser();

  // eslint-disable-next-line @typescript-eslint/no-use-before-define

  const checkMultiFund = (fundNumber: string): CurrentFund => {
    switch (Number(fundNumber)) {
      case 2:
        return {
          legalName: 'fund2LegalName',
          capital: 'capitalCommitmentFund2'
        };
      case 3:
        return {
          legalName: 'fund3LegalName',
          capital: 'capitalCommitmentFund3'
        };

      default:
        return {
          legalName: 'fundLegalName',
          capital: 'capitalCommitment'
        };
    }
  };

  useEffect(() => {
    if (user?.firm) {
      page = 1;
      getFund(id);
      getInvestors(id);
    }
  }, []); //eslint-disable-line

  if (!user) {
    return <Redirect to="/login" />;
  }

  const getFund = async (identifier: string): Promise<void> => {
    const { data } = await axios.get(
      `/clients/firm/${user.firm._id}/funds/1/100`
    );
    const fund = data.funds.filter((fund: IFund) => fund._id === identifier)[0];
    setFund(fund);
  };

  const getInvestors = async (identifier: string): Promise<void> => {
    setLoading(true);
    const { data } = await axios.get(
      `/clients/fund/${identifier}/investors/1/500`
    );
    const response = await axios.get(
      `/clients/fund/${identifier}/investors/${page}/${pagination.MAX - 1}`
    );

    const filteredData = response?.data?.investors
      .filter((inv: IInvestor) => inv?.status !== 'omitted')
      .filter((inv: IInvestor) => checkGeneralInfoCompleted(inv.generalInfo));

    const results = filteredData.map((inv: IInvestor, index: number) => {
      const capital = inv.generalInfo
        ? inv.generalInfo[checkMultiFund(fundNumber).capital]
        : 0;
      return {
        capitalCommitment: `${formatter.format(capital)}`,
        name: inv.generalInfo ? inv.generalInfo.name : '',
        lpGroupText: inv.lpGroup ? inv.lpGroup : '',
        lpGroup: inv.lpGroup ? (
          <Tooltip>
            <b>{inv.lpGroup.replace(/(.{15})..+/, '$1…')}</b>
            <div className="tooltip-text">
              <b>{inv.lpGroup}</b>
            </div>
          </Tooltip>
        ) : (
          ''
        ),
        notes: inv.notes ? (
          <TooltipExpand
            className={`
              ${index === filteredData.length - 1 ? 'tooltip-last-child' : ''}
            `}
          >
            <NoteIcon />
            <p className="tooltip-text">{inv.notes}</p>
          </TooltipExpand>
        ) : (
          <NoteIconOpacity />
        ),
        status: resolveStatus(inv.status, inv.submitted),
        statusContent: (
          <>
            <Tooltip>
              <StyledDot
                status={formatStatusLabel(inv.status, inv.submitted)}
              />
              &nbsp;
              {resolveStatus(inv.status, inv.submitted)}
              <div className="tooltip-text">
                <b>
                  {dateFormat(
                    new Date(inv.closedAt || inv.updatedAt),
                    'dd mmm yyyy'
                  )}
                </b>
                {/* &nbsp;
                {dateFormat(new Date(inv.closedAt || inv.updatedAt), 'h:MM TT')} */}
              </div>
            </Tooltip>
          </>
        ),
        contactName: inv.generalInfo?.namePrimaryContact || '',
        contactEmail: (
          <AnchorMail
            href={`mailto:${inv.generalInfo?.emailPrimaryContact || ''}`}
          >
            {inv.generalInfo?.emailPrimaryContact || ''}
          </AnchorMail>
        )
      };
    });
    const count = Math.ceil(data.investors.length / pagination.MAX);
    const pages = Array.from(Array(count).keys()).reverse();
    setPages(pages);
    const validResults: ResultsType[] = [];
    results.forEach((e: any) => {
      if (e.name && parseExpr(e.capitalCommitment)) {
        validResults.push(e);
      }
    });
    setResults(validResults);
    const validInvestors: ResultsType[] = [];
    data.investors.forEach((e: any) => {
      if (
        e.generalInfo.name &&
        e.generalInfo[checkMultiFund(fundNumber).capital]
      ) {
        validInvestors.push(e);
      }
    });

    setCSVData(formatDataInvestors(validInvestors, checkMultiFund(fundNumber)));
    setLoading(false);
  };

  const getStatusInfo = (
    status: string
  ): { capital: number; count: number } => {
    let capital = 0;
    if (results && results.length > 0) {
      const filteredResults = results.filter(
        (investor) => investor.status === status
      );

      if (filteredResults.length > 0) {
        filteredResults.forEach((investor) => {
          if (investor.capitalCommitment) {
            capital += Number(
              investor.capitalCommitment.replace(/[^0-9.-]+/g, '')
            );
          }
        });

        return { capital, count: filteredResults.length };
      }
    }

    return { capital, count: 0 };
  };

  const getAllCapital = (): { capital: number; count: number } => {
    let capital = 0;
    if (results && results.length > 0) {
      results.forEach((investor) => {
        if (investor.capitalCommitment) {
          capital += Number(
            investor.capitalCommitment.replace(/[^0-9.-]+/g, '')
          );
        }
      });
      return { capital, count: results.length };
    }

    return { capital, count: 0 };
  };

  const getNotYetReadyToCloseStatus = (
    status: string,
    status2?: string,
    status3?: string,
    status4?: string
  ): { capital: number; count: number } => {
    let filteredResults: any = [];
    if (results && results.length > 0) {
      filteredResults = results.filter(
        (investor) =>
          investor.status === status ||
          investor.status === status2 ||
          investor.status === status3 ||
          investor.status === status4 ||
          !investor.status
      );
    }
    let capital = 0;

    if (filteredResults.length > 0) {
      filteredResults.forEach((investor: any) => {
        if (investor.capitalCommitment) {
          capital += Number(
            investor.capitalCommitment.replace(/[^0-9.-]+/g, '')
          );
        }
      });

      return { capital, count: filteredResults.length };
    } else {
      return { capital, count: 0 };
    }
  };

  const nextPage = (): void => {
    page += 1;
    setResults([]);
    getInvestors(id);
  };

  const previusPage = (): void => {
    if (page > 1) {
      page -= 1;
      setResults([]);
      getInvestors(id);
    }
  };

  const selectedPage = (e?: React.MouseEvent<HTMLElement>): void => {
    if (!e) {
      return;
    }
    page = Number((e.target as HTMLElement).innerText);
    setResults([]);
    getInvestors(id);
  };

  const parseExpr = (exp: string): number => {
    if (!exp) return 0;
    return (
      (exp[0] === '<' ? -1 : 1) + parseInt(exp.replace(/[,.]/g, '').substr(1))
    );
  };

  const clearInvestors = async (): Promise<void> => {
    setResults([]);
  };
  const sortHandler = async (record: any): Promise<void> => {
    setSortClick(sortClick + 1);
    const temporalResults = results;
    const key = record.key as keyof ResultsType;
    await clearInvestors();

    if (columnSelected !== record.title) {
      setSortClick(1);
    }

    if (sortClick >= 2 && columnSelected === record.title) {
      setColumnSelected('');
      await clearInvestors();
      getInvestors(id);
      setSortClick(0);
      setSortDir('');
    } else if (columnSelected === record.title && sortDir === 'desc') {
      setResults(temporalResults.reverse());
      setSortDir('asc');
    } else {
      setColumnSelected(record.title);
      setSortDir('desc');
      if (record.key === 'capitalCommitment') {
        results.sort(
          (a, b) =>
            parseExpr(b['capitalCommitment']) -
            parseExpr(a['capitalCommitment'])
        );
        setResults(results);
      } else {
        const sortByKey = (value: ResultsType): string => {
          switch (key) {
            case 'contactEmail':
              //eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              return value[key]?.props?.children;
            case 'statusContent':
              return value.status;
            case 'lpGroup':
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              return value.lpGroupText || '';
            default:
              return value[key];
          }
        };
        const sortedResults = results.sort((a: ResultsType, b: ResultsType) => {
          const aSort = a[key] ? sortByKey(a)?.toLowerCase() : '';
          const bSort = b[key] ? sortByKey(b)?.toLowerCase() : '';

          if (aSort === '' || !aSort) {
            return 1;
          }
          if (bSort === '' || !bSort) {
            return -1;
          }
          if (aSort === bSort) {
            return 0;
          }
          return aSort.localeCompare(bSort);
        });
        setResults(sortedResults);
      }
    }
  };

  return (
    <Layout title={fund[checkMultiFund(fundNumber).legalName]}>
      <HeaderPage>
        <InfoBox
          amount={`$${nFormatter(getAllCapital().capital)}`}
          fullAmount={formatFullAmount(getAllCapital().capital)}
          title="All Commitments"
        />
        <InfoBox
          amount={`$${nFormatter(
            getNotYetReadyToCloseStatus(
              status.IN_PROGRESS,
              status.PENDING,
              status.SUBMITTED_TYPE,
              status.SUBMITTED
            ).capital
          )}`}
          fullAmount={formatFullAmount(
            getNotYetReadyToCloseStatus(
              status.IN_PROGRESS,
              status.PENDING,
              status.SUBMITTED_TYPE,
              status.SUBMITTED
            ).capital
          )}
          title="Not Yet Ready to Close"
        />
        <InfoBox
          amount={`$${nFormatter(
            getStatusInfo(status.READY_TO_CLOSE).capital
          )}`}
          fullAmount={formatFullAmount(
            getStatusInfo(status.READY_TO_CLOSE).capital
          )}
          title="Ready to Close"
        />
        <InfoBox
          amount={`$${nFormatter(getStatusInfo(status.CLOSED).capital)}`}
          fullAmount={formatFullAmount(getStatusInfo(status.CLOSED).capital)}
          title="Closed"
        />
      </HeaderPage>
      <DataTable
        cancelSort={async () => {}}
        columns={fundDetailTableColumns}
        columnSelected={columnSelected}
        csvData={csvData}
        csvTitle={fund[checkMultiFund(fundNumber).legalName]}
        data={results}
        emptyText={loading ? 'Loading...' : ''}
        headers={fundDetailHeaders}
        onRowClick={() => {}}
        onSearch={(e) => {
          setSearchText(e.target.value.toLowerCase());
          if (e.target.value !== '') {
            const _results = results.filter((result) =>
              result.name?.toLowerCase().includes(e.target.value.toLowerCase())
            );
            setResults(_results);
          } else {
            getInvestors(id);
          }
        }}
        pagination={{
          next: () => nextPage(),
          page,
          pages,
          previus: () => previusPage(),
          selected: (e) => selectedPage(e)
        }}
        search={searchText}
        sortDirection={sortDir}
        sortHandler={sortHandler}
        tableHeaderColumns={false}
        title="Investors"
      />
    </Layout>
  );
};

interface DotProps {
  status: string;
}

const StyledDot = styled.span<DotProps>`
  background-color: ${(props) => {
    switch (props.status) {
      case status.CLOSED:
        return colors.status.closed;
      case status.IN_PROGRESS:
        return colors.status.inProgress;
      case status.PENDING:
        return colors.status.inProgress;
      case status.SUBMITTED:
        return colors.status.submitted;
      case status.READY_TO_CLOSE:
        return colors.status.readyToClose;
      default:
        return colors.status.inProgress;
    }
  }};
  border-radius: 50%;
  display: inline-block;
  height: 10px;
  width: 10px;
`;

const HeaderPage = styled.section`
  display: flex;
  height: 200px;
  text-align: center;
`;

const AnchorMail = styled.a`
  color: ${colors.dark.black};
  text-decoration: none;
`;
